<template>
  <div id="container">
    <div class="box" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <loading v-model:active="isLoading" :is-full-page="fullPage" />

    <div class="box" v-if="weatherData">
      <img :src="weatherImage" />
      <p v-if="weatherData.name">{{ weatherData.name }}</p>
      <h1 v-if="weatherData.main.temp">
        {{ currentTemp }}
      </h1>
      <div v-if="weatherData.main.feels_like && feelsLikeTemp != currentTemp">
        <p>érzésre:</p>
        <h2>{{ feelsLikeTemp }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';

import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data() {
    return {
      isLoading: true,
      fullPage: true,
      errorMessage: null,
      userCoordinates: null,
      weatherData: null,
    };
  },
  components: {
    Loading,
  },
  computed: {
    currentTemp() {
      return Math.round(this.weatherData.main.temp - 273.15);
    },
    feelsLikeTemp() {
      return Math.round(this.weatherData.main.feels_like - 273.15);
    },
    weatherImage() {
      return (
        'https://openweathermap.org/img/wn/' +
        this.weatherData.weather[0].icon +
        '@2x.png'
      );
    },
  },
  methods: {
    getUserLocation() {
      this.$getLocation()
        .then((coordinates) => {
          this.getWeatherData(coordinates);
        })
        .catch(() => {
          this.getWeatherData();
        });
    },
    getWeatherData(coordinates) {
      let apiLink = null;
      const apiKey = 'c1ca27b95fd0469c6fa59fdb015a60c9',
        apiUrl = 'https://api.openweathermap.org/data/2.5/weather';

      if (coordinates) {
        apiLink = `${apiUrl}?lat=${coordinates.lat}&lon=${coordinates.lng}&appid=${apiKey}`;
      } else {
        apiLink = `${apiUrl}?q=Budapest&appid=${apiKey}`;
      }

      axios
        .get(apiLink)
        .then((response) => {
          this.weatherData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.errorMessage = error;
        });
    },
  },
  created() {
    this.getUserLocation();
  },
};
</script>

<style scoped>
/* https://stackoverflow.com/questions/19026884/flexbox-center-horizontally-and-vertically */
#container {
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  height: 300px;
}

.box {
  width: 300px;
  margin: 5px;
  text-align: center; /* will center text in <p>, which is not a flex item */
}
</style>
